<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat dark color="primary">
					<v-toolbar-title> {{ $t("SmartNavBar.My_profile") }} </v-toolbar-title>
				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
		</v-row>
		<v-row justify="center">
			<v-col cols="6">
				<v-card flat>
					<v-card-title>{{ $t('Info') }}</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" v-for="field in fields" :key="field.name">
								<v-text-field v-model="rowObject[field.name]" hide-details="auto"
									v-on:input="onDataModified" :readonly="readOnly" :label="field.label" :disabled="'disabled' in field
										? field.disabled
										: false
										" :type="'type' in field
		? field.type
		: 'text'
		" :outlined="!readOnly &&
		('disabled' in field ? false : true)
		" />
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn icon color="primary" plain :disabled="!readOnly" v-on:click.stop="beginUpdate"><v-icon
								left>mdi-pencil-outline</v-icon>
						</v-btn>
						<v-btn icon color="primary" :loading="loading" :disabled="!dirty"
							v-on:click="saveChanges"><v-icon>mdi-check-outline</v-icon></v-btn><v-btn icon color="red"
							:disabled="readOnly" v-on:click="cancelUpdate"><v-icon>mdi-close</v-icon></v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

		</v-row>
		<!-- NOTIFICATIONS
		<v-row justify="center">
			<v-col cols="6">
				<v-card flat>
					<v-card-title>{{ $t('UserProfile.Notifications') }}</v-card-title>
					<v-card-subtitle>{{ $t('UserProfile.Subscribe_to_notifications') }}</v-card-subtitle>
					<v-card-text>
						<v-checkbox v-model="checkbox1" :label="$t('UserProfile.Subscribe_to_order_confirmations')"
							hide-details="auto" /><v-checkbox v-model="checkbox2"
							:label="$t('UserProfile.Subscribe_to_shipments')" hide-details="auto" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	-->
		<v-row justify="center">
			<!-- CHANGE PASSWORD -->
			<v-col cols="6">
				<v-card flat width="auto">
					<v-card-title>{{ $t('UserProfile.Security') }}</v-card-title>
					<v-card-subtitle>
						{{ $t('UserProfile.You_can_reset_your_password_here') }}
					</v-card-subtitle>
					<v-card-actions><v-btn text plain @click.stop="resetPassword" :loading="loading">{{
						$t('UserProfile.Reset_password') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import caas from "@/services/caas";
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
	name: "UserProfile",
	data() {
		return {
			tabId: 0,
			readOnly: true,
			dirty: false,
			loading: false,
			result: "",
			errorMsg: "",
			checkbox1: false,
			checkbox2: false,
			fields: [
				{ name: "klnr", label: this.$t("Customer_nr"), disabled: true },
				{ name: "klantnaam", label: this.$t("Customer_name"), disabled: true },
				{ name: "voornaam", label: this.$t("First_name") },
				{ name: "naam", label: this.$t("Last_name") },
			],
			appUrl: process.env.VUE_APP_APP_URL,
			rowObject: {},
			rowObjectBefore: {},
		};
	},
	computed: {
		...mapGetters({
			mailTo: "session/firebaseUserEmail",
			userName: "session/userName",
			userId: "session/userId",
		}),
	},
	methods: {
		openQuery() {
			var sdoName = "web/objects/dEgebruiker.r";
			var params = {
				action: "read",
				nbrOfRows: 1,
				setOpenQuery:
					"for each extgebruiker where gebruikerid = " +
					this.userId +
					" no-lock indexed-reposition",
			};
			this.loading = true;
			caas.sdo(sdoName, params)
				.then((response) => {
					this.rowObject = response.data.success.data.RowObject[0];
					this.loading = false;
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
		beginUpdate() {
			this.readOnly = false;
			Object.assign(this.rowObjectBefore, this.rowObject);
		},
		cancelUpdate() {
			this.dirty = false;
			this.readOnly = true;
			Object.assign(this.rowObject, this.rowObjectBefore);
		},
		onDataModified() {
			this.dirty = true;
		},
		saveChanges() {
			this.loading = true;
			var sdoName = "web/objects/dEgebruiker.r",
				params = {
					action: "update",
					rowObjUpd: [],
				};
			// original record
			params.rowObjUpd.push(this.rowObjectBefore);
			// modified record
			this.rowObject.rowMod = "U";
			params.rowObjUpd.push(this.rowObject);
			caas.sdo(sdoName, params)
				.then((response) => {
					this.loading = false;
					this.readOnly = true;
					this.dirty = false;
					if (response.data.success) {
						this.openQuery();
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
		async resetPassword() {
			this.loading = true;
			const apiClient = axios.create({
				baseURL: process.env.VUE_APP_CLOUDFUNCTIONS_ENDPOINT,
				withCredentials: false,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			});
			const data = {
				tenantId: Number(process.env.VUE_APP_TENANT_ID),
				email: this.mailTo,
				url: process.env.VUE_APP_APP_URL,
				language: navigator.language.split('-')[0]
			}
			try {
				await apiClient.post("/authentication-portalUsers-resetPassword", data);
				this.$store.dispatch(
					"session/showBottomMessage",
					this.$t('UserProfile.An_email_has_been_sent_to') + this.mailTo
				);
				this.loading = false;
			} catch (error) {
				this.$store.dispatch(
					"session/showBottomMessage",
					this.$t('Something_went_wrong') + ": " + error.message
				);
				this.loading = false;
			}
		},

	},
	mounted() {
		this.openQuery();
	},
};
</script>
